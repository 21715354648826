import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import { Divisor } from "../components/Tourus/Divisor";
import Button from "@material-ui/core/Button";
import Grow from "@material-ui/core/Grow";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl, FormControlLabel, hexToRgb,
  Input, InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Zoom,
  List,
  ListItem
} from "@material-ui/core";
import { Menu, ShoppingCart } from "@material-ui/icons";
import CardBody from "../components/Card/CardBody";
import Card from "../components/Card/Card";
import Slider from "@material-ui/core/Slider";
import Swal from 'sweetalert2';
import Sidebar from "react-sidebar";

import { getCarrinhoStorageByCompany, setCarrinhoStorage } from "../helpers/localStorage";
import { MenuCategoria } from "../components/Tourus/MenuCategoria";

import styles from '../assets/styles/pedido-cliente.module.css';


export const segmentoTradutor = {
  food: {
    ingrediente: { nome: 'Ingrediente', nomeS: 'Ingredientes' },
    sabor: { nome: 'Sabor', nomeS: 'Sabores' },
    adicional: { nome: 'Adicional', nomeS: 'Adicionais' }
  },
  market: {
    ingrediente: { nome: 'Item', nomeS: 'Itens' },
    sabor: { nome: 'Opção', nomeS: 'Opções' },
    adicional: { nome: 'Adicional', nomeS: 'Adicionais' }
  }
};

const pontuacaoNumero = valor => {
  if (!valor) return "";
  let valorFinal = "";
  valor = valor.toString().split("").reverse().join("");
  for (let i = valor.length - 1; i >= 0; i--) {
    valorFinal += valor[i];

    if (((i % 3) === 0) && (i > 0))
      valorFinal += ".";
  }
  return valorFinal;
}

const formatoDinheiro = valor => {
  valor = parseFloat(valor).toFixed(2).toString().split(".");
  let valorFinal = pontuacaoNumero(valor[0]);
  valorFinal = valorFinal + "," + valor[1];
  return "R$ " + valorFinal;
}

const getHoursBetween = (hour, startHour, endHour) => {
  const [hourVerify, minuteVerify] = hour.split(':').map(parseFloat);
  const [startHourPeriod, startMinutePeriod] = startHour.split(':').map(parseFloat);
  const [endHourPeriod, endMinutePeriod] = endHour.split(':').map(parseFloat);

  if (hourVerify >= startHourPeriod && hourVerify <= endHourPeriod) {
    return (hourVerify === startHourPeriod ? minuteVerify >= startMinutePeriod : true) && (hourVerify === endHourPeriod ? minuteVerify <= endMinutePeriod : true);
  } else return false;
}

function zeroEsquerda(numero, casas = 2) {
  numero = parseInt(numero || 0, 10);
  casas = (casas - 1);
  if (numero < Math.pow(10, casas)) {
    numero = new Array(casas).fill("0").join("") + numero;
  }
  return numero.toString();
}

const checkHours = openHours => {
  if (!openHours.length) return true;

  openHours = openHours.filter(el => parseFloat(el.weekDay) === new Date().getDay());
  const horaAgora = `${zeroEsquerda(new Date().getHours())}:${zeroEsquerda(new Date().getMinutes())}`;
  let dentroPeriodo = false;
  for (let i = 0; i < openHours.length; i += 1) {
    const { start, end } = openHours[i];
    if (getHoursBetween(horaAgora, start, end)) {
      dentroPeriodo = true;
      break;
    }
  }

  return dentroPeriodo;
};

const getMenorValorAdicionalProduto = produto => {
  const adicionaisObrigatorios = produto.additional.filter(el => el.required);
  let valorTotal = 0;
  for (let i = 0; i < adicionaisObrigatorios.length; i += 1) {
    const { items } = adicionaisObrigatorios[i];
    const menorValor = Math.min(...items.map(prop => prop.price));
    valorTotal += menorValor;
  }
  return valorTotal;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />;
});

class PedidoCliente extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carrinho: [],
      adicionarItemCarrinhoModal: {
        open: false,
        idProduto: 0,
        quantidade: 1,
        adicionais: [],
        obsevacao: '',
        keyCategory: null,
        keyProduct: null,
        produto: {},
        additional: {},
        sabor: '',
        size: ''
      },
      categorias: props.dados.categorias,
      produtos: props.dados.produtos,
      sidebarOpen: false,
    };

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  /*componentDidUpdate(nextProps, nextContext) {
    console.log('UPDATE', nextProps.dados);
    if (nextProps.dados && nextProps.dados.categorias) {
      console.log(nextProps.dados.categorias);
      this.setState({ categorias: nextProps.dados.categorias });
    }
  }*/

  async getFromWM() {
    /*const { data } = await axios.get(`https://adm.whatsmenu.com.br/api/v2/business/${this.props.dados.slug}`);
    const { categories } = data;
    const allCategorias = [];
    for (let i = 0; i < categories.length; i++) {
      const { name, products } = categories[i];
      if (!products) continue;
      const categoria = { name, action: '', icon: SanduicheIcone, produtos: [] };
      for (let j = 0; j < products.length; j++) {
        // console.log(products[j]);
        const { name, description, image, value, promoteValue, id } = products[j];
        categoria.produtos.push({
          id,
          nome: name.trim().split('  ').join(' '),
          ingredientes: description && description.trim(),
          image,
          valor: value,
          desconto: promoteValue ? value - promoteValue : 0
        });
      }
      allCategorias.push(categoria);
    }
    this.setState({ categorias: allCategorias });*/
  }

  onSetSidebarOpen() {
    if (this.state.sidebarOpen)
      this.setState({ sidebarOpen: false });
    else
      this.setState({ sidebarOpen: true });
  }

  scrollTo(tag_id) {
    document.getElementById(tag_id).scrollIntoView({ behavior: "smooth", block: "start" });
    this.setState({ sidebarOpen: false })
  }

  getMenu(categorias) {
    return (
      <List style={{ paddingTop: 20 }}>
        <h4 style={{ textAlign: 'center', color: 'black' }}>Categorias</h4>
        {categorias.map(({ name }, key) => {
          return (
            <div style={{ padding: '0px' }} onClick={() => this.scrollTo('cat-' + (name).replace(" ", "").toLowerCase())} > <MenuCategoria label={name} /> </div>
          )
        })
        }
      </List>
    )

  }

  componentDidMount() {
    // const clientCart = JSON.parse(localStorage.getItem('@clientCart') || null);
    const clientCart = getCarrinhoStorageByCompany(this.props.dados.slug)
    console.log("Client cart: ", clientCart)
    if (clientCart) {
      clientCart.date = new Date(clientCart.date);
      if (((new Date() - clientCart.date) / 1000) < 2 * 60 * 60) { // Expiração do carrinho - 2 horas
        this.setState({ carrinho: clientCart.carrinho })
      } else {
        // localStorage.setItem('@clientCart', '');
        setCarrinhoStorage(null, this.props.dados.slug)
      }
    } else {
      // carrinho vazio -> adiciona chave na storage
      setCarrinhoStorage(null, this.props.dados.slug)
    }
    // this.getFromWM();

    try {
      const el = document.getElementById("menu-icon");
      if (el) el.addEventListener("click", this.onSetSidebarOpen, false);
    } catch (e) {
      // *
    }
  }

  async finalizaPedido() {
    const pedido = {
      carrinho: this.state.carrinho,
      flavors: this.props.flavors,
      additional: this.state.additional,
      date: new Date(),
    };

    setCarrinhoStorage(pedido, this.props.dados.slug);
    this.props.history.push(`/${this.props.dados.slug}/pedidos/finalizar`, pedido);
  }

  fecharModalCarrinho() {
    this.setState({
      adicionarItemCarrinhoModal: {
        open: false,
        idProduto: 0,
        quantidade: 1,
        adicionais: [],
        obsevacao: '',
        produto: {},
        keyCategory: null,
        keyProduct: null,
        sabor: '',
        additional: []
      }
    });
  }

  render() {
    return (
      <div style={{ paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>

        {/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
        <Dialog
          open={this.state.adicionarItemCarrinhoModal.open}
          TransitionComponent={Transition}
          keepMounted={false}
          fullWidth
          // fullScreen
          // maxWidth={'xl'}
          onClose={this.fecharModalCarrinho.bind(this)}
        >
          <DialogTitle>
            {this.state.adicionarItemCarrinhoModal.produto.nome}
            -
            {formatoDinheiro(
              this.state.adicionarItemCarrinhoModal.produto.valor
              // + (Object.keys(this.state.adicionarItemCarrinhoModal.additional).reduce((total, prop) => total + (this.state.adicionarItemCarrinhoModal.additional[prop].quantity * this.state.adicionarItemCarrinhoModal.additional[prop].price), 0))
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText align={"center"}>
              <img
                style={{ width: 'calc(100% - 20px)', borderRadius: 15, maxHeight: 150, maxWidth: 150 }}
                src={this.state.adicionarItemCarrinhoModal.produto.image}
                alt={this.state.adicionarItemCarrinhoModal.produto.name}
              />
              {/*<div
                style={{
                  width: 'calc(100% - 20px)',
                  height: 100,
                  backgroundImage: `url(${this.state.adicionarItemCarrinhoModal.produto.image})`,
                  borderRadius: 15,
                  border: '2px solid',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPositionY: 'center',
                  backgroundPositionX: 'center'
                }}
              >
                &nbsp;
              </div>*/}
              <Divisor label={'Quantidade'} style={{ color: '#000' }} color={this.props.dados.cores.destaque} />
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  width: 'calc(100% - 15px)',
                  // maxWidth: 300,
                  textAlign: 'center',
                  marginTop: 20
                }}
              >
                <div
                  style={{
                    width: '40%',
                    background: this.props.dados.cores.principal,
                    borderRadius: '10px 0px 0px 10px',
                    color: '#FFF'
                  }}
                  onClick={() => {
                    if (this.state.adicionarItemCarrinhoModal.quantidade === 1) return;
                    this.setState({
                      adicionarItemCarrinhoModal: {
                        ...this.state.adicionarItemCarrinhoModal,
                        quantidade: this.state.adicionarItemCarrinhoModal.quantidade - 1
                      }
                    });
                  }}
                >
                  -
                </div>
                <div style={{ width: '20%', background: this.props.dados.cores.secundaria, color: '#FFF' }}>
                  {this.state.adicionarItemCarrinhoModal.quantidade}
                </div>
                <div
                  style={{
                    width: '40%',
                    background: this.props.dados.cores.principal,
                    borderRadius: '0px 10px 10px 0px',
                    color: '#FFF'
                  }}
                  onClick={() => {
                    this.setState({
                      adicionarItemCarrinhoModal: {
                        ...this.state.adicionarItemCarrinhoModal,
                        quantidade: this.state.adicionarItemCarrinhoModal.quantidade + 1
                      }
                    });
                  }}
                >
                  +
                </div>
              </div>

              {this.state.adicionarItemCarrinhoModal.keyCategory !== null && this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors.length && this.state.adicionarItemCarrinhoModal.produto.flavorLimit > 1 ? (
                <>
                  <br />
                  <Divisor label={segmentoTradutor[this.props.dados.segment].sabor.nomeS} style={{ color: '#000' }} />
                  {this.state.adicionarItemCarrinhoModal.produto.flavorLimit ? (
                    <div align={'left'}>
                      <small>Escolha até {this.state.adicionarItemCarrinhoModal.produto.flavorLimit} {segmentoTradutor[this.props.dados.segment].sabor.nomeS.toLowerCase()}</small>
                    </div>
                  ) : ''}
                  <GridContainer>
                    {this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors.map((prop, keyFlavor) => (
                      <GridItem lg={4} md={4} sm={4} xs={6}>
                        <FormControlLabel
                          style={{float: 'left'}}
                          control={
                            <Checkbox
                              color={'primary'}
                              checked={this.state.adicionarItemCarrinhoModal.sabor.toString().split(',').map(parseFloat).filter(el => !isNaN(el)).indexOf(keyFlavor) > -1}
                              onChange={async () => {
                                let { adicionarItemCarrinhoModal } = this.state;
                                adicionarItemCarrinhoModal.sabor = adicionarItemCarrinhoModal.sabor.split(',').map(parseFloat);

                                adicionarItemCarrinhoModal.sabor = adicionarItemCarrinhoModal.sabor.filter(el => !isNaN(el));

                                if (adicionarItemCarrinhoModal.sabor.indexOf(keyFlavor) === -1) adicionarItemCarrinhoModal.sabor.push(keyFlavor);
                                else delete adicionarItemCarrinhoModal.sabor[adicionarItemCarrinhoModal.sabor.indexOf(keyFlavor)];

                                adicionarItemCarrinhoModal.sabor = adicionarItemCarrinhoModal.sabor.filter(el => !isNaN(parseFloat(el))).join(',');

                                this.setState({
                                  adicionarItemCarrinhoModal: { ...this.state.adicionarItemCarrinhoModal }
                                });
                              }}
                              disabled={
                                (this.state.adicionarItemCarrinhoModal.sabor.toString().split(',').map(parseFloat).filter(el => !isNaN(el)).length >= parseFloat(this.state.adicionarItemCarrinhoModal.produto.flavorLimit)) &&
                                !(this.state.adicionarItemCarrinhoModal.sabor.toString().split(',').map(parseFloat).filter(el => !isNaN(el)).indexOf(keyFlavor) > -1)
                              }
                              name="checkedA"
                            />
                          }
                          label={prop.name}
                        />
                      </GridItem>
                    ))}
                  </GridContainer>
                </>
              ) : ''}

              {this.state.adicionarItemCarrinhoModal.keyCategory !== null && this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors.length && (this.state.adicionarItemCarrinhoModal.produto.flavorLimit ? this.state.adicionarItemCarrinhoModal.produto.flavorLimit <= 1 : true) ? (
                <>
                  <br />
                  {/*<Divisor label={'Sabores'} style={{ color: '#000' }}/>*/}
                  <FormControl fullWidth>
                    <InputLabel>Selecione o {segmentoTradutor[this.props.dados.segment].sabor.nome.toLowerCase()}</InputLabel>
                    <Select
                      color={'primary'}
                      fullWidth
                      // multiple
                      value={this.state.adicionarItemCarrinhoModal.sabor}
                      onChange={({ target: { value } }) => {
                        this.setState({
                          adicionarItemCarrinhoModal: {
                            ...this.state.adicionarItemCarrinhoModal,
                            sabor: value
                          }
                        });
                      }}
                      input={<Input />}
                      // renderValue={(selected) => selected.map(prop => this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors[prop].name).join(', ')}
                      MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                    >
                      {this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors.map((prop, keyFlavor) => (
                        <MenuItem key={prop.name} value={keyFlavor}>
                          <ListItemText
                            primary={prop.name}
                            secondary={prop.price ? formatoDinheiro(prop.price) : ''}
                            style={{ textAlign: 'left' }} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : ''}

              {this.state.adicionarItemCarrinhoModal.keyCategory !== null && this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct]?.sizes?.length ? (
                <>
                  <p />
                  {/*<Divisor label={'Tamanho'} style={{ color: '#000' }}/>*/}
                  <FormControl fullWidth>
                    <InputLabel>Selecione o tamanho</InputLabel>
                    <Select
                      color={'primary'}
                      fullWidth
                      value={this.state.adicionarItemCarrinhoModal.size}
                      onChange={({ target: { value } }) => {
                        this.setState({
                          adicionarItemCarrinhoModal: {
                            ...this.state.adicionarItemCarrinhoModal,
                            size: value
                          }
                        });
                      }}
                      input={<Input />}
                      MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                    >
                      {this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].sizes.map((prop, keySize) => (
                        <MenuItem key={prop.name} value={keySize}>
                          <ListItemText
                            primary={prop.name}
                            secondary={prop.price ? formatoDinheiro(prop.price) : ''}
                            style={{ textAlign: 'left' }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              ) : ''}

              {this.state.adicionarItemCarrinhoModal.keyCategory !== null && this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].additional.length ? (
                <>
                  <Divisor label={'Adicionais'} style={{ color: '#000' }} />
                  <GridContainer>
                    {
                      this.state.adicionarItemCarrinhoModal.keyCategory !== null ?
                        this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].additional.map(prop => {
                          const { name, quantityMin, quantityMax, required, items } = prop;
                          return (
                            <>
                              <GridItem lg={12} md={12} sm={12} xs={12}>
                                <Card>
                                  <div className={styles.container}>
                                    <h3 className={styles.title}>{name}</h3>

                                    <div className={styles.itemsContainer}>
                                {items.map(({ name: nameItem, description, price, id_product_additional_item }) => (
                                        <div className={styles.itemContainer}>
                                          <b>{nameItem}</b>

                                          <p>{description}</p>

                                          <div className={styles.itemPriceContainer}>
                                            <span>{formatoDinheiro(price)}</span>
                                            
                                            <div className={styles.itemQuantityContainer}>
                                              <div
                                                style={{
                                                  width: '40%',
                                                  background: this.props.dados.cores.principal,
                                                  borderRadius: '10px 0px 0px 10px',
                                                  color: '#FFF'
                                                }}
                                                onClick={() => {
                                                  const { additional } = this.state.adicionarItemCarrinhoModal;

                                                  if (!additional[id_product_additional_item])
                                                    additional[id_product_additional_item] = { quantity: 0, price };

                                                  if (additional[id_product_additional_item].quantity <= 0) return false;
                                                  additional[id_product_additional_item].quantity -= 1;

                                                  this.setState({
                                                    adicionarItemCarrinhoModal: {
                                                      ...this.state.adicionarItemCarrinhoModal,
                                                      additional
                                                    }
                                                  })
                                                }}
                                              >
                                                -
                                              </div>
                                              <div
                                                style={{
                                                  width: '20%',
                                                  background: this.props.dados.cores.secundaria,
                                                  color: '#FFF'
                                                }}
                                              >
                                                {this.state.adicionarItemCarrinhoModal.additional[id_product_additional_item] ? this.state.adicionarItemCarrinhoModal.additional[id_product_additional_item].quantity : 0}
                                              </div>
                                              <div
                                                style={{
                                                  width: '40%',
                                                  background: this.props.dados.cores.principal,
                                                  borderRadius: '0px 10px 10px 0px',
                                                  color: '#FFF',
                                                  opacity: Object.keys(this.state.adicionarItemCarrinhoModal.additional).map(prop => ({
                                                    ...this.state.adicionarItemCarrinhoModal.additional[prop],
                                                    id_product_additional_item: parseFloat(prop)
                                                  })).filter(el => (items.map(prop => prop.id_product_additional_item).indexOf(el.id_product_additional_item) > -1)).reduce((total, prop) => total + prop.quantity, 0) >= quantityMax ? 0.5 : 1
                                                }}
                                                onClick={() => {
                                                  const { additional } = this.state.adicionarItemCarrinhoModal;

                                                  const totalAdditional = Object.keys(additional).map(prop => ({
                                                    ...additional[prop],
                                                    id_product_additional_item: parseFloat(prop)
                                                  })).filter(el => (items.map(prop => prop.id_product_additional_item).indexOf(el.id_product_additional_item) > -1)).reduce((total, prop) => total + prop.quantity, 0);

                                                  if (!additional[id_product_additional_item])
                                                    additional[id_product_additional_item] = { quantity: 0, price };

                                                  if (totalAdditional >= quantityMax) return false;
                                                  additional[id_product_additional_item].quantity += 1;

                                                  this.setState({
                                                    adicionarItemCarrinhoModal: {
                                                      ...this.state.adicionarItemCarrinhoModal,
                                                      additional
                                                    }
                                                  })
                                                }}
                                              >
                                                +
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </Card>
                              </GridItem>
                            </>
                          );
                        }) : ''
                    }
                  </GridContainer>
                </>
              ) : ''}
              <p />
              {/*<Divisor label={'Observações'} style={{ color: '#000' }}/>*/}
              <TextField
                fullWidth
                label="Observações"
                multiline
                // TODO - Alterar valor observação
                rows={4}
                onChange={({ target: { value } }) => {
                  this.setState({
                    adicionarItemCarrinhoModal: {
                      ...this.state.adicionarItemCarrinhoModal,
                      obsevacao: value
                    }
                  })
                }}
                defaultValue=""
              />
              {/*<h2>{this.state.categorias[0].produtos[0].nome}</h2>*/}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.fecharModalCarrinho.bind(this)} color="danger">
              Cancelar
            </Button>
            <Button
              variant={'contained'}
              disableElevation
              onClick={() => {
                const {
                  idProduto,
                  quantidade,
                  obsevacao,
                  sabor,
                  additional,
                  size
                } = this.state.adicionarItemCarrinhoModal;
                const {
                  flavors,
                  sizes
                } = this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct];
                if (flavors && flavors.length && (sabor === null || sabor === undefined || sabor === '')) {
                  Swal.fire({ type: "info", title: `É necessário selecionar um ${segmentoTradutor[this.props.dados.segment].sabor.nomeS.toLowerCase()} para o produto!` });
                  return false;
                }

                if (sizes && sizes.length && (size === null || size === undefined || size === '')) {
                  Swal.fire({ type: "info", title: 'É necessário selecionar o tamanho do produto!' });
                  return false;
                }

                this.setState({
                  carrinho: {
                    ...this.state.carrinho,
                    [idProduto]: {
                      quantidade,
                      obsevacao,
                      sabor: (sabor !== null && sabor !== undefined && sabor !== '') && (
                        sabor.toString().split(',').map(parseFloat).filter(el => !isNaN(el)).map(prop =>
                          this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].flavors[prop].id_product_flavor
                        )
                      ),
                      size: (size !== null && size !== undefined && size !== '') && this.state.categorias[this.state.adicionarItemCarrinhoModal.keyCategory].produtos[this.state.adicionarItemCarrinhoModal.keyProduct].sizes[size].id_product_size,
                      additional
                    }
                  }
                });
                this.fecharModalCarrinho();
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                })

                Toast.fire('Item adicionado com sucesso!', '', 'success');
                // Swal.fire({ type: "success", title:  });
              }}
              color="primary"
            >
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        {/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
        <Fab
          style={{ position: 'fixed', bottom: 10, zIndex: 999, right: 10 }}
          disabled={!Object.keys(this.state.carrinho).length}
          onClick={this.finalizaPedido.bind(this)}
          color={'primary'}
        >
          {Object.keys(this.state.carrinho).length ? (
            <div
              style={{
                background: this.props.dados.cores.principal,
                color: '#FFF',
                position: 'absolute',
                width: 20,
                height: 20,
                borderRadius: '50%',
                top: -4,
                right: 6
              }}
            >
              {Object.keys(this.state.carrinho).length}
            </div>
          ) : ''}
          <ShoppingCart />
        </Fab>
        <GridContainer justify={"center"}>
          <GridItem lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
            {!checkHours(this.props.dados.openHours) ? (
              <div
                style={{
                  backgroundColor: this.props.dados.cores.principal,
                  width: 'calc(100% - 10px)',
                  padding: 5,
                  color: '#FFF',
                  borderRadius: 5,
                  boxShadow: '5px 5px 14px -10px white'
                }}
              >
                O estabelecimento não está aberto no momento e por isso não está aceitando pedidos.
                <table style={{ width: '100%' }}>
                  {new Array(7).fill(null).map((p, k) => k).map(prop => {
                    const labels = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
                    const horariosDia = this.props.dados.openHours.filter(el => parseFloat(el.weekDay) === prop);

                    return (
                      <tr
                        key={prop} style={{
                          width: '100%',
                          backgroundColor: parseFloat(prop) === new Date().getDay() ? `rgba(0, 0, 0, 0.2)` : ''
                        }}
                      >
                        <th>{labels[prop]}</th>
                        <td>
                          {horariosDia.map(prop1 => {
                            return (
                              <>
                                <small>{prop1.start.split(':').slice(0, 2).join(':')} às {prop1.end.split(':').slice(0, 2).join(':')}</small>
                                <br />
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            ) : ''}
          </GridItem>

          {!this.state.categorias ? '' :
            <Sidebar
              sidebar={this.getMenu(this.state.categorias)}
              open={this.state.sidebarOpen}
              onSetOpen={this.onSetSidebarOpen}
              touch={false}
              styles={{
                sidebar: {
                  background: "white",
                  color: "black",
                  width: '55%',
                  maxWidth: 300,
                  position: 'fixed',
                  paddingTop: "60px"
                },
                root: {},
                content: {}
              }}
            />
          }

          <GridItem lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
            <span style={{ color: "white", textTransform: 'uppercase', fontWeight: 'bold' }}>
              Catálogo
            </span>
          </GridItem>
          {this.state.categorias.map(({ name, icon: Icon, produtos }, key) => (
            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              timeout={key * 500}
              key={key}
            >
              <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
                <Divisor label={name} color={this.props.dados.cores.destaque} />
                <GridContainer>
                  {this.state.categorias[key].produtos.filter(el => el.flagActive).map((prop1, keyProduct) => (
                    <GridItem lg={2} md={3} sm={4} xs={6} style={{ display: 'grid' }}>
                      <div
                        style={{
                          color: '#282C34',
                          backgroundColor: '#FFFFFF',
                          borderRadius: 10,
                          marginTop: 15,
                          fontFamily: 'poppins-semiBold',
                          paddingTop: 10,
                          position: 'relative',
                          boxShadow: '0px 0px 15px -5px #ddd'
                        }}
                        onClick={() => ''}
                      // fullWidth
                      >
                        <img
                          style={{ width: 'calc(100% - 20px)', borderRadius: 15, maxHeight: 200, maxWidth: 200 }}
                          src={prop1.image}
                          alt={prop1.name}
                        />
                        {/*<div style={{
                          width: 'calc(100% - 20px)',
                          height: 100,
                          backgroundImage: `url(${prop1.image})`,
                          // left: 5,
                          borderRadius: 15,
                          border: '2px solid',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPositionY: 'center',
                          backgroundPositionX: 'center'
                        }}>
                          &nbsp;
                        </div>*/}
                        <div>
                          {prop1.nome}
                        </div>
                        <small style={{ fontSize: 10 }}>
                          {prop1.ingredientes.map(prop2 => (
                            <p style={{ margin: 0, textAlign: 'start', marginLeft: 5, marginRight: 5 }}>
                              * {prop2.name}
                            </p>
                          ))}
                        </small>
                        <p style={{ height: 45 }} />
                        <small
                          style={{
                            bottom: 50,
                            position: 'absolute',
                            left: 0,
                            width: '100%',
                            textDecoration: prop1.desconto ? 'line-through' : 'none'
                          }}
                        >
                          {prop1.desconto ? formatoDinheiro(
                            prop1.valor +
                            getMenorValorAdicionalProduto(this.state.produtos[prop1.id_product])
                          ) : <>&nbsp; </>}
                        </small>
                        <div
                          style={{
                            width: 'calc(100% - 10px)',
                            // background: '#000',
                            // display: 'flex',
                            padding: 5,
                            position: 'absolute',
                            bottom: 25,
                            // marginLeft: '25%'
                            // left: 'calc(25% - 30px)'
                          }}
                        >
                          {formatoDinheiro(
                            (prop1.valor - (prop1.desconto || 0)) +
                            getMenorValorAdicionalProduto(this.state.produtos[prop1.id_product])
                          )}
                        </div>
                        <div
                          style={{
                            width: 'calc(100% - 10px)',
                            display: 'flex',
                            padding: 5,
                            position: 'absolute',
                            bottom: 0
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              background: this.props.dados.cores.principal,
                              borderRadius: 10,
                              color: '#FFF'
                            }}
                            onClick={() => {
                              if (!checkHours(this.props.dados.openHours)) {
                                Swal.fire({
                                  type: "info",
                                  title: 'Estabelecimento fechado.',
                                  text: 'O estabelecimento não está aberto no momento e por isso não está aceitando pedidos.'
                                });
                                return false;
                              }

                              this.setState({
                                adicionarItemCarrinhoModal: {
                                  open: true,
                                  idProduto: prop1.id_product,
                                  quantidade: 1,
                                  adicionais: [],
                                  produto: this.state.produtos[prop1.id_product],
                                  keyCategory: key,
                                  keyProduct,
                                  obsevacao: '',
                                  sabor: '',
                                  additional: {},
                                  size: ''
                                }
                              });
                            }}
                          >
                            Adicionar
                          </div>
                          {/*<div
                            style={{
                              width: '40%',
                              background: this.props.dados.cores.principal,
                              borderRadius: '10px 0px 0px 10px',
                              color: '#FFF'
                            }}
                            onClick={() => {
                              if (!this.state.carrinho[prop1.id] || !this.state.carrinho[prop1.id].quantidade) return;
                              this.state.carrinho[prop1.id].quantidade--;
                              if (!this.state.carrinho[prop1.id].quantidade) delete this.state.carrinho[prop1.id];
                              this.setState({ carrinho: this.state.carrinho });
                            }}
                          >
                            -
                          </div>
                          <div style={{ width: '20%', background: this.props.dados.cores.secundaria, color: '#FFF' }}>
                            {this.state.carrinho[prop1.id] ? this.state.carrinho[prop1.id].quantidade : 0}
                          </div>
                          <div
                            style={{
                              width: '40%',
                              background: this.props.dados.cores.principal,
                              borderRadius: '0px 10px 10px 0px',
                              color: '#FFF'
                            }}
                            onClick={() => {
                              if (!this.state.carrinho[prop1.id]) this.state.carrinho[prop1.id] = { quantidade: 0 };
                              this.state.carrinho[prop1.id].quantidade++;
                              this.setState({ carrinho: this.state.carrinho });
                            }}
                          >
                            +
                          </div>*/}
                        </div>
                      </div>
                    </GridItem>
                  ))}
                </GridContainer>
                {/*<Button
                  style={{
                    color: '#282C34',
                    backgroundColor: '#FFFFFF',
                    fontFamily: 'poppins-semiBold'
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    this.props.history.push(`/pedidos/categoria/${name}`, { name, produtos });
                  }}
                  fullWidth
                >
                  <div style={{ float: 'left', position: 'absolute', left: 10, top: 3 }}>
                    <Icon/>
                  </div>
                  <div style={{ float: 'left', position: 'absolute', left: 40, top: 5 }}>
                    Opções
                  </div>
                  &nbsp;
                </Button>*/}
              </GridItem>
            </Grow>
          ))}
          <p>&nbsp; </p>
        </GridContainer>
      </div>
    );
  }
}

export default PedidoCliente;
