import React, { useState } from "react";
import HeaderLinks from "../components/Header/HeaderLinks";
import { createBrowserHistory } from "history";
import { AnimatedSwitch } from 'react-router-transition';
import { Route, Router } from "react-router-dom";
import ClienteRotas from "../routes/index";
import { PacmanLoader } from "react-spinners";
import { SanduicheIcone } from "../assets/icons/Icones";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import api from "../api/api";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import { configHost } from "../dados";
import { io } from "socket.io-client";

moment.locale('pt-br');

const history = createBrowserHistory();

function LightenDarkenColor(p, c0, c1) {
  var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt;
  if (c0.length > 7) {
    var f = c0.split(","), t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","), R = w(f[0].slice(4)),
      G = w(f[1]), B = w(f[2]);
    return "rgb(" + (u((w(t[0].slice(4)) - R) * n) + R) + "," + (u((w(t[1]) - G) * n) + G) + "," + (u((w(t[2]) - B) * n) + B) + ")"
  } else {
    var f = w(c0.slice(1), 16), t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16), R1 = f >> 16,
      G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;
    return "#" + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
  }
}

const LoadRouterProps = ({ component: Component, ...props }) => <Route render={p => <Component {...props} {...p}/>}/>;

const defaultColors = { principal: '#fb8c00', secundaria: '#ffa726', destaque: '#ffd4b3' };

class Cliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tema: createMuiTheme({ palette: { primary: { main: '#FF0000' } } }),
      dados: {
        logo: '',
        logoNome: '',
        nome: '',
        slug: '',
        categorias: [],
        produtos: {},
        cores: { ...defaultColors }
      }
    };
  }

  async getEmpresa() {
    try {
      const empresa = window.location.pathname.split('/')[1];
      if (!empresa) {
        window.location.href = 'https://site.hfood.app';
        return;
      }

      // TODO - Melhorar ou remover futuramente
      if (empresa === 'braseado-gourmet') {
        window.location.href = 'https://www.ifood.com.br/delivery/serra-es/braseado-gourmet-eurico-salles/c18de282-dc87-4d40-b680-017fcbbab370?UTM_Medium=share';
        return;
      }

      const { data } = await api.get(`/company/${empresa}`);
      const { name, slug, productCategories, uuid, colorPrimary, colorSecondary, colorText, lastModification, ...rest } = data;

      // ------------------------------------------
      const empresasLS = JSON.parse(localStorage.getItem('@companies')) || [];
      if (empresasLS.indexOf(slug) === -1) {
        empresasLS.push(slug);
        localStorage.setItem('@companies', JSON.stringify(empresasLS));
      }

      // ***************************************************************************************************************
      const localSocket = io(configHost);

      localSocket.on('connect', () => {
        localSocket.emit('Conn:Config:SetLanguage', (navigator.language || navigator.userLanguage).toString());
        localSocket.emit('client:auth:company', slug);

        const clientWhatsapp = localStorage.getItem('@client-whatsapp');
        if (clientWhatsapp) localSocket.emit('client:auth:user', clientWhatsapp.toString());
      });
      // ***************************************************************************************************************

      this.setState({ dados: { ...data, ...this.state.dados } });

      const allCategorias = [];
      const allProdutos = {};
      for (let i = 0; i < productCategories.length; i++) {
        const { name, products } = productCategories[i];
        if (!products) continue;

        const categoria = { name, action: '', icon: SanduicheIcone, produtos: [] };
        for (let j = 0; j < products.length; j++) {
          const { id_product, name, price, discount, ingredients, images, additional, flavors, flagActive, sizes, flavorLimit } = products[j];
          const produto = {
            flagActive,
            id_product,
            nome: name,
            ingredientes: ingredients,
            image: `https://storage.googleapis.com/hust-menu-files/${((images && images[0] && images[0].uuid) || 'image-placeholder')}.png`,
            valor: price,
            desconto: discount,
            additional,
            flavors,
            sizes,
            flavorLimit
          };
          allProdutos[id_product] = produto;
          categoria.produtos.push(produto);
        }
        allCategorias.push(categoria);
      }

      const logoImage = `https://storage.googleapis.com/hust-menu-files/${uuid}.png${lastModification ? `?${new Date(lastModification).getTime()}` : ''}`;

      window.document.title = name;
      document.getElementById('favicon').href = logoImage;

      // const { primary, secondary, text } = colors || {};

      document.body.style.backgroundColor = colorSecondary || defaultColors.secundaria;
      this.setState({
        dados: {
          ...rest,
          logo: logoImage,
          nome: name,
          slug,
          categorias: allCategorias,
          produtos: allProdutos,
          cores: {
            principal: colorPrimary || defaultColors.principal,
            secundaria: colorSecondary || defaultColors.secundaria,
            destaque: colorText || defaultColors.destaque
          }
        },
        tema: createMuiTheme({ palette: { primary: { main: colorText || defaultColors.destaque } } }),
        loading: false
      });
    } catch (e) {
      let mensagem = e.toString();
      if (e.response && e.response.data && (e.response.data.message || e.response.data.userMessage)) {
        const { response: { data } } = e;
        mensagem = data.message || data.userMessage;
      }

      if (mensagem === 'Error: Empresa não encontrada no sistema.')
        window.location.href = 'https://site.hfood.app';
      return false;
    }
  }

  componentDidMount() {
    if (window.location.pathname.split('/')[1] !== 'meus-locais')
      this.getEmpresa();
    else
      this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ width: '100%', height: '100vh', background: '#FFF' }}>
          <PacmanLoader
            size={50}
            // color={infoColor[0]}
            loading={true}
            css={`position: absolute; top: calc(50% - 25px); left: calc(50% - 50px);`}
          />
        </div>
      );
    }

    return (
      <ThemeProvider theme={this.state.tema}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {
            (window.location.pathname.split('/')[1] !== 'meus-locais') ?
              <HeaderLinks history={history} dados={this.state.dados}/> :
              ''
          }
          <Router history={history}>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              {ClienteRotas.map((prop, key) => {
                const { component, path } = prop;
                return (
                  <LoadRouterProps
                    dados={this.state.dados}
                    component={component}
                    key={key}
                    exact={prop.exact || false}
                    path={path}
                  />
                );
              })}
            </AnimatedSwitch>
            {/*<Footer/>*/}
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default Cliente;
