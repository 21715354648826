import React from "react";

export const Divisor = props => (
  <div style={{ marginBottom: 10, marginTop: 10, height: 30 }} onClick={props.onClick}>
    <small
      id={'cat-' + (props.label).replace(" ", "").toLowerCase()}
      style={{
        float: 'left',
        width: '100%',
        textAlign: 'start',
        color: 'white',
        fontFamily: 'poppins-regular',
        ...(props.style || {})
      }}>
      {props.label}
    </small>
    <hr
      style={{
        backgroundColor: props.color || '#BA822F',
        color: props.color || '#BA822F',
        height: 1,
        border: 'none',
        width: '50%',
        float: 'left'
      }}
    />
  </div>
);
