import React, { useEffect, useState } from "react";
import { Divisor } from "../components/Tourus/Divisor";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import {
  Fab, FormControl, FormControlLabel, FormLabel, Input, InputAdornment, InputLabel, ListItemText, MenuItem,
  Radio, RadioGroup, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField
} from "@material-ui/core";
import { Close, ShoppingCart } from "@material-ui/icons";
import Cep from 'cep-promise';
import Swal from 'sweetalert2';
import Button from "@material-ui/core/Button";
import api from "../api/api";
import { DateTimePicker } from "@material-ui/pickers";
import { clearCarrinhoStorage, getCarrinhoStorageByCompany, updateCarrinhoStorage } from "../helpers/localStorage";
import InputMask from 'react-input-mask';
import MaterialInput from '@material-ui/core/Input';
import { segmentoTradutor } from "./PedidoCliente";

const pontuacaoNumero = valor => {
  if (!valor) return "";
  let valorFinal = "";
  valor = valor.toString().split("").reverse().join("");
  for (let i = valor.length - 1; i >= 0; i--) {
    valorFinal += valor[i];

    if (((i % 3) === 0) && (i > 0))
      valorFinal += ".";
  }
  return valorFinal;
}

const formatoDinheiro = valor => {
  valor = parseFloat(valor).toFixed(2).toString().split(".");
  let valorFinal = pontuacaoNumero(valor[0]);
  valorFinal = valorFinal + "," + valor[1];
  return "R$ " + valorFinal;
}

const resetScheduleTime = () => {
  const d = new Date();
  d.setHours(d.getHours() + 2);
  d.setMinutes(0);
  return d;
};

const defaultBairro = {
  id_company_delivery_district: null,
  city: null,
  district: '',
  time: '',
  price: 0
};

const PedidoFinalizar = (props) => {
  const { history: { push, location: { state } }, dados } = props;
  const [carrinho, setCarrinho] = useState([]);
  const [cep, setCep] = useState('');
  const [thing, setThing] = useState(0);
  const [numero, setNumero] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [observacao, setObservacao] = useState('');
  const [UF, setUF] = useState('');
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState(defaultBairro);

  const [payment, setPayment] = useState('');
  const [tipoEntrega, setTipoEntrega] = useState('');

  const [agendarPedido, setAgendarPedido] = useState(false);
  const [dateSchedule, setDateSchedule] = useState(resetScheduleTime());

  const [nome, setNome] = useState(localStorage.getItem('@client-name') || '');
  const [whatsapp, setWhatsApp] = useState(localStorage.getItem('@client-whatsapp') || '');

  useEffect(() => {
    setAgendarPedido(false);
    setDateSchedule(resetScheduleTime());
  }, [tipoEntrega]);

  useEffect(() => {
    if (!cep && cep.length !== 8) return;
    const JsonEndereco = { cep, numero, logradouro, observacao, UF, cidade, bairro: bairro.id_company_delivery_district };
    localStorage.setItem('endereco', JSON.stringify(JsonEndereco));
  }, [cep, numero, logradouro, observacao, UF, cidade, bairro]);

  useEffect(() => {
    const { cep, numero, logradouro, observacao, UF, cidade, bairro } = JSON.parse(localStorage.getItem('endereco')) || {};
    setCep(cep || '');
    setNumero(numero || '');
    setLogradouro(logradouro || '');
    setObservacao(observacao || '');
    setUF(UF || '');
    setCidade(cidade || '');

    const bairroStorage = props.dados.deliveryDistrict.find(el => parseFloat(el.id_company_delivery_district) === parseFloat(bairro));
    setBairro(bairroStorage || defaultBairro);
  }, []);

  useEffect(() => {
    if (state && state.carrinho) {
      const { carrinho } = state;
      Object.keys(carrinho).map(prop => {
        carrinho[prop].adicionais = carrinho[prop].additional;
      });
      setCarrinho(Object.keys(carrinho).map(prop => {
        return { ...carrinho[prop], ...dados.produtos[prop] };
      }));
    }
  }, [state, dados]);

  const finalizarPedido = async () => {
    const companySlug = window.location.pathname.split('/')[1];
    if (!carrinho.length) push(`/${window.location.pathname.split('/')[1]}/pedidos`);

    const { value } = await Swal.fire({
      type: "question",
      title: 'Deseja confirmar o pedido?',
      text: 'Ao confirmar seu pedido será enviado para a loja!',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    // const endereco = { cep, numero, logradouro, observacao, UF, cidade };
    // const { flavors, adicionais } = carrinho;
    const order = {
      client: {
        uuid: localStorage.getItem('@clientUUID'),
        name: nome,
        phone: whatsapp
      },
      products: carrinho.map(prop => ({
        id: prop.id_product,
        observation: prop.obsevacao || '', // TODO - Remover
        quantity: prop.quantidade,
        flavors: prop.sabor || [],
        size: prop.size,
        additional: Object.keys(prop.adicionais).length ? Object.keys(prop.adicionais).map(prop1 => ({
          quantity: prop.adicionais[prop1].quantity,
          id_product_additional_item: parseFloat(prop1)
        })) : []
      })),
      address: tipoEntrega === 'retirada' ? 'retirada' : {
        cep,
        street: logradouro,
        complement: observacao,
        number: numero,
        city: cidade,
        uf: UF
      },
      id_company_delivery_district: bairro && bairro.id_company_delivery_district,
      payment,
      thing,
      companySlug,
      dateSchedule: agendarPedido ? dateSchedule.toISOString() : null
    };

    const { data } = await api.post(`/order`, order);
    clearCarrinhoStorage(props.dados.slug)

    if (order.payment === 'pix')
      return push(`/${window.location.pathname.split('/')[1]}/pedidos/finalizado`, data);

    await Swal.fire({
      type: "success",
      title: 'Pedido realizado com sucesso!',
      text: 'Aguarde e em breve seu pedido será entregue!'
    });


    push(`/${window.location.pathname.split('/')[1]}`);
  };

  useEffect(() => {
    localStorage.setItem('@client-name', nome || '');
    localStorage.setItem('@client-whatsapp', whatsapp || '');
  }, [nome, whatsapp]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(console.log);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  const renderCarrinhoAdicional = () => {
    let valorTotalAdicionalCarrinho = 0;
    for (let i = 0; i < carrinho.length; i += 1) {
      const { additional, adicionais } = carrinho[i];
      const { totais: { valorTotal } } = renderProductAditional(additional, adicionais)
      valorTotalAdicionalCarrinho += valorTotal;
    }
    return valorTotalAdicionalCarrinho;
  };

  const renderCarrinhoAdicionalSabor = () => {
    var calculo = 0;
    var flavors, sabor;
    carrinho.map(function (prop, keyCarrinho) {
      flavors = prop.flavors;
      sabor = prop.sabor;
    });

    if (sabor) {
      sabor.map(function (prop, index) {
        if (prop) {
          let adicionalSabor = flavors.find(x => x.id_product_flavor === prop).price;
          if (adicionalSabor) {
            calculo += parseFloat(adicionalSabor)
          }
        }
      });
    }

    return calculo;
  };

  const renderProductAditional = (additional, adicionais) => {
    const categories = {};

    Object.keys(adicionais).filter(el => adicionais[el].quantity).map(prop => {
      const items = {};
      additional.map(prop => prop.items).forEach(prop => prop.forEach(prop1 => items[prop1.id_product_additional_item] = prop1));
      const totalAdicionalLinha = adicionais[prop].quantity * items[prop].price;

      for (let i = 0; i < additional.length; i++) {
        const { items, name, mergePrice, id_product_additional, ...rest } = additional[i];
        const itemFind = items.find(el => el.id_product_additional_item === parseFloat(prop));
        if (!categories[id_product_additional]) categories[id_product_additional] = { mergePrice, name, selected: [] };

        if (itemFind)
          categories[id_product_additional].selected.push({ ...adicionais[prop], name: itemFind.name });
      }

      // console.log(adicionais[prop]);

      // totalValueAdicionais += totalAdicionalLinha;
      // return (
      //   <>
      //     <small>&nbsp;&nbsp;{adicionais[prop].quantity}x {items[prop].name} - {formatoDinheiro(totalAdicionalLinha)}<br/></small>
      //   </>
      // );
    });

    const adicionaisGeral = [];
    let valorTotal = 0;
    let maiorValor = 0;

    const configMaiorValor = true;

    for (let i = 0; i < Object.keys(categories).length; i++) {
      let valorTotalCategoria = 0;
      let countDivision = 0;
      const { mergePrice, name, selected } = categories[Object.keys(categories)[i]];
      for (let j = 0; j < selected.length; j++) {
        const { price, quantity } = selected[j];
        if (price > maiorValor) maiorValor = price;
        valorTotalCategoria += (price * quantity);
        countDivision += 1;
      }

      if (mergePrice) {
        if (configMaiorValor)
          valorTotalCategoria = maiorValor;
        else
          valorTotalCategoria /= countDivision;
      }

      valorTotal += valorTotalCategoria;
      adicionaisGeral.push({ name, valorTotalCategoria, selected });
    }

    // console.log(categories);
    return {
      componente: adicionaisGeral.map(prop => (
        <>
          <small>
            &nbsp;&nbsp;&nbsp;{prop.name}
            ({prop.selected.map(prop => `${prop.name} - ${formatoDinheiro(prop.price)}`).join(', ')})
            - {formatoDinheiro(prop.valorTotalCategoria)}
          </small>
          <br />
        </>
      )),
      totais: {
        valorTotal
      }
    };
  };

  // console.log(state);
  // console.log(dados);
  let totalValueAdicionais = 0;
  return (
    <div style={{ paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
      <Fab
        variant="extended"
        size="medium"
        color="success"
        style={{ position: 'fixed', bottom: 10, zIndex: 999, right: 10 }}
        onClick={finalizarPedido.bind(this)}
      >
        <ShoppingCart />
        Finalizar pedido
      </Fab>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Divisor label={'Dados pessoais'} color={props.dados.cores.destaque} />
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    label="Nome *"
                    fullWidth
                    value={nome}
                    type={'text'}
                    inputProps={{
                      "autocomplete": 'autocomplete_off_hu5t',
                      "aria-autocomplete": 'none'
                    }}
                    onChange={({ target: { value } }) => setNome(value)}
                  />
                </GridItem>
                <GridItem lg={6} md={6} sm={6} xs={12}>
                  <InputMask mask="(99) 9 9999-9999" value={whatsapp} onChange={({ target: { value } }) => setWhatsApp((value || '').toString().split('').filter(el => !isNaN(parseFloat(el))).join(''))}>
                    {(inputProps) => <TextField {...inputProps} type="tel" fullWidth label={'WhatsApp *'}/>}
                  </InputMask>
                  {/*<InputMask
                    mask="(99) 9 9999-9999"
                    value={whatsapp}
                    // disabled={false}
                    // onChange={({ target: { value } }) => setWhatsApp(value)}
                    maskChar=" "
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="WhatsApp *"
                        fullWidth
                        // value={whatsapp}
                        type={'number'}
                        // onChange={({ target: { value } }) => setWhatsApp(value)}
                      />
                    )}
                  </InputMask>*/}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <Divisor label={'Dados de entrega'} color={props.dados.cores.destaque} />
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      color={'primary'}
                      fullWidth
                      value={tipoEntrega}
                      // value={'retirada'}
                      onChange={({ target: { value } }) => setTipoEntrega(value)}
                      input={<Input />}
                      MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                    >
                      {props.dados.flagWithdraw || !props.dados.deliveryDistrict.length ? (
                        <MenuItem value={'retirada'}>
                          <ListItemText primary={'Retirada'}/>
                        </MenuItem>
                      ) : ''}
                      {props.dados.deliveryDistrict.length ? (
                        <MenuItem value={'entrega'}>
                          <ListItemText primary={'Entrega'}/>
                        </MenuItem>
                      ) : ''}
                    </Select>
                  </FormControl>
                </GridItem>
                {tipoEntrega === 'retirada' && props.dados.flagScheduleWithdraw ? (
                  <>
                    <GridItem lg={3} md={4} sm={4} xs={12}>
                      <p/>
                      <FormControlLabel
                        control={
                          <Switch
                            color={'primary'}
                            checked={agendarPedido}
                            onChange={() => setAgendarPedido(!agendarPedido)}
                          />
                        }
                        label="Agendar retirada?"
                      />
                      {agendarPedido ? (
                        <>
                          <p/>
                          <DateTimePicker
                            fullWidth
                            autoOk
                            ampm={false}
                            variant="inline"
                            value={dateSchedule}
                            disablePast
                            onChange={date => {
                              if (date <= new Date()) return Swal.fire({
                                type: "info",
                                title: 'Não é possível agendar para o passado.'
                              });
                              setDateSchedule(date);
                            }}
                            label="Data/Hora da retirada"
                            showTodayButton
                            format="DD/MM/yyyy HH:mm"
                          />
                        </>
                      ) : ''}
                    </GridItem>
                  </>
                ) : ''}
                {tipoEntrega === 'entrega' ? (
                  <>
                    <GridItem lg={3} md={4} sm={4} xs={12}>
                      <p />
                      <FormControl fullWidth>
                        <InputLabel>Bairro</InputLabel>
                        <Select
                          color={'primary'}
                          fullWidth
                          value={bairro.id_company_delivery_district}
                          onChange={({ target: { value } }) => {
                            const bairro = props.dados.deliveryDistrict.find(el => parseFloat(el.id_company_delivery_district) === parseFloat(value));
                            setBairro({ ...bairro });
                            setCidade(bairro.city);
                          }}
                          input={<Input />}
                          MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
                        >
                          {props.dados.deliveryDistrict.map((prop, keyDistrict) => {
                            const { id_company_delivery_district, city, district, time, price } = prop;
                            return (
                              <MenuItem key={district} value={id_company_delivery_district}>
                                <ListItemText primary={district} />
                                <small>{time} min - {formatoDinheiro(price)}</small>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem lg={3} md={4} sm={4} xs={6}>
                      <p />
                      <TextField
                        label="CEP"
                        fullWidth
                        value={cep}
                        inputProps={{ "autocomplete": 'autocomplete_off_hu5t', "aria-autocomplete": 'none' }}
                        type={'number'}
                        onChange={async ({ target: { value } }) => {
                          setCep(value);
                          if (value.length === 8) {
                            try {
                              Swal.fire({ title: 'Carregando' });
                              Swal.showLoading();
                              const { city, neighborhood, state, street } = await Cep(value);
                              // setCidade(city);
                              // setUF(state);
                              setLogradouro(`${street} - ${neighborhood}`);
                              Swal.close();
                              Swal.hideLoading();
                            } catch (e) {
                              // setCidade('');
                              // setUF('');
                              setLogradouro('');
                              setCep('');
                              Swal.hideLoading();
                              Swal.fire({
                                title: 'Não foi possível encontrar o CEP informado',
                                type: "error",
                                text: e.toString()
                              });
                            }
                          }
                        }} />
                    </GridItem>
                    <GridItem lg={3} md={4} sm={4} xs={6}>
                      <p />
                      <TextField
                        label="Nº"
                        fullWidth
                        value={numero}
                        onChange={({ target: { value } }) => setNumero(value)} />
                    </GridItem>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <p />
                      <TextField
                        label="Logradouro"
                        fullWidth
                        rows={2}
                        multiline={true}
                        value={logradouro}
                        onChange={({ target: { value } }) => setLogradouro(value)} />
                    </GridItem>
                    <GridItem lg={4} md={4} sm={6} xs={12}>
                      <p />
                      <TextField
                        label="Observação"
                        fullWidth
                        rows={2}
                        multiline={true}
                        value={observacao}
                        onChange={({ target: { value } }) => setObservacao(value)} />
                    </GridItem>
                    {/*<GridItem lg={3} md={4} sm={4} xs={4}>
                      <p/>
                      <TextField label="UF" fullWidth value={UF} onChange={({ target: { value } }) => setUF(value)}/>
                    </GridItem>*/}
                    <GridItem lg={3} md={4} sm={4} xs={8}>
                      <p />
                      <TextField
                        disabled
                        label="Cidade"
                        fullWidth
                        value={cidade}
                        inputProps={{ "autocomplete": 'autocomplete_off_hu5t', "aria-autocomplete": 'none' }}
                        onChange={({ target: { value } }) => setCidade(value)} />
                    </GridItem>
                    {/********************************************/}
                    {props.dados.flagScheduleDelivery ? (
                      <GridItem lg={3} md={4} sm={4} xs={12}>
                        <p/>
                        <FormControlLabel
                          control={
                            <Switch
                              color={'primary'}
                              checked={agendarPedido}
                              onChange={() => setAgendarPedido(!agendarPedido)}
                            />
                          }
                          label="Agendar entrega?"
                        />
                        {agendarPedido ? (
                          <>
                            <p/>
                            <DateTimePicker
                              fullWidth
                              autoOk
                              ampm={false}
                              variant="inline"
                              value={dateSchedule}
                              disablePast
                              onChange={date => {
                                if (date <= new Date()) return Swal.fire({
                                  type: "info",
                                  title: 'Não é possível agendar para o passado.'
                                });
                                setDateSchedule(date);
                              }}
                              label="Data/Hora da entrega"
                              showTodayButton
                              format="DD/MM/yyyy HH:mm"
                            />
                          </>
                        ) : ''}
                      </GridItem>
                    ) : ''}
                    {/********************************************/}
                  </>
                ) : ''}
                {/*<GridItem lg={3} md={4} sm={4} xs={8}>
                  <Button color={'primary'} onClick={getLocation}>
                    Localização
                  </Button>
                </GridItem>*/}
              </GridContainer>
            </CardBody>
          </Card>

          <Divisor label={'Dados de pagamento'} color={props.dados.cores.destaque} />
          <Card>
            <CardBody>
              <FormControl component="fieldset">
                <FormLabel component="legend">Opções de pagamento</FormLabel>
                <RadioGroup onChange={(e, value) => setPayment(value)}>
                  {props.dados.flagPaymentMoney ? (
                    <FormControlLabel
                      color={'primary'}
                      value="money"
                      control={<Radio color={'primary'} />}
                      label="Dinheiro"
                    />
                  ) : ''}
                  {props.dados.flagPaymentCredit ? (
                    <FormControlLabel
                      color={'primary'}
                      value="card-debit"
                      control={<Radio color={'primary'} />}
                      label="Cartão de crédito"
                    />
                  ) : ''}
                  {props.dados.flagPaymentDebit ? (
                    <FormControlLabel
                      color={'primary'}
                      value="card-credit"
                      control={<Radio color={'primary'} />}
                      label="Cartão de débito"
                    />
                  ) : ''}
                  {props.dados.flagPaymentPix ? (
                    <FormControlLabel
                      color={'primary'}
                      value="pix"
                      control={<Radio color={'primary'} />}
                      label="PIX"
                    />
                  ) : ''}
                </RadioGroup>
              </FormControl>
              {payment === 'money' ? (
                <>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel>Troco para</InputLabel>
                    <Input
                      value={thing}
                      type={'number'}
                      onChange={({ target: { value } }) => setThing(value)}
                      startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                    />
                  </FormControl>
                </>
              ) : ''}
              {payment === 'pix' ? (
                <>
                  <br />
                  <div
                    style={{
                      width: '100%',
                      background: 'rgba(0,0,0,0.6)',
                      textAlign: 'center',
                      borderRadius: 5,
                      color: '#FFF',
                      paddingTop: 5,
                      paddingBottom: 5
                    }}
                  >
                    Após a realização do pedido será gerado o QRCode para pagamento
                  </div>
                </>
              ) : ''}
            </CardBody>
          </Card>

          <Divisor label={'Resumo do pedido'} color={props.dados.cores.destaque} />
          <Card>
            <CardBody>
              <Table>
                <TableHead>
                  <TableRow>
                    {/*<TableCell>
                      Quantidade
                    </TableCell>*/}
                    <TableCell>
                      Descrição
                    </TableCell>
                    <TableCell>
                      Valor
                    </TableCell>
                    {/*<TableCell/>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {carrinho.map((prop, keyCarrinho) => {
                    const {
                      quantidade,
                      nome,
                      image,
                      valor,
                      desconto,
                      sabor,
                      flavors,
                      additional,
                      adicionais,
                      size
                    } = prop;
                    const flavor = (sabor && Array.isArray(sabor) ? sabor.map(prop => flavors.find(el => el.id_product_flavor === prop)) : flavors.find(el => el.id_product_flavor === sabor)) || {};
                    const sizeConfig = (((prop.size && prop.sizes.find(el => el.id_product_size === prop.size)) || {}) || {});
                    const adicionalSabor = (sabor && Array.isArray(sabor) ? sabor.map(prop => flavors.find(el => el.id_product_flavor === prop)) : flavors.find(el => el.id_product_flavor === sabor)) || {};


                    const aditionalItems = {};
                    /*Object.keys(adicionais).map(prop => {
                      console.log(prop);
                      console.log(additional);
                      const ad = additional.find(el => parseFloat(el.id_product_additional) === parseFloat(prop));
                      console.log(ad);
                      const { items } = ad;
                      for (let i = 0; i < items.length; i++) {
                        aditionalItems[items[0].id_product_additional_item] = items[i];
                      }
                    })*/
                    return (
                      <>
                        <TableRow hover>
                          <TableCell style={{ borderBottom: 'none', paddingBottom: 0 }}>
                            {quantidade}x {nome}
                            <br />
                            {Object.keys(adicionais).length ? (
                              <>
                                <br />
                                Adicionais:<br />
                                {renderProductAditional(additional, adicionais).componente}
                              </>
                            ) : ''}
                            {/*{additional.filter(el => el => )}*/}

                            {sabor && flavor ? (
                              <>
                                <br />
                                {segmentoTradutor[props.dados.segment].sabor.nome}: {flavor.map(prop => prop.name).join(', ')}
                                <br />
                                {adicionalSabor.map((prop, keyFlavor) => (
                                  <span> {prop.price ? (`Adicional ${segmentoTradutor[props.dados.segment].sabor.nomeS.toLowerCase().toLowerCase()}: + ` + formatoDinheiro(prop.price)) : ''}</span>
                                ))}
                              </>
                            ) : ''}

                            {sizeConfig.price ? (
                              <>
                                <br />
                                Tamanho {sizeConfig.name}: + {formatoDinheiro(sizeConfig.price)}
                              </>
                            ) : ''}
                          </TableCell>
                          <TableCell style={{ borderBottom: 'none', paddingBottom: 0 }}>
                            {formatoDinheiro(
                              (valor - desconto) +
                              (sabor ? (flavor.reduce((total, prop) => total + prop.price, 0) || 0) : 0) +
                              (sizeConfig.price ? parseFloat(sizeConfig.price) : 0) +
                              (renderProductAditional(additional, adicionais).totais.valorTotal)
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={2} style={{ paddingTop: 5 }}>
                            <Button
                              fullWidth
                              style={{ padding: 0 }}
                              onClick={async () => {
                                const { value } = await Swal.fire({
                                  type: 'question',
                                  title: 'Deseja remover o item do carrinho?',
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonText: 'Sim'
                                });

                                if (value) {
                                  let temp = getCarrinhoStorageByCompany(props.dados.slug);
                                  delete temp.carrinho[carrinho[keyCarrinho].id_product]
                                  setCarrinho(c => {
                                    delete c[keyCarrinho];

                                    return [...c.filter(el => el)];
                                  });
                                  updateCarrinhoStorage(temp, props.dados.slug)
                                  let pedido = {
                                    carrinho: temp.carrinho,
                                    flavors: props.history.location.state.flavors || {},
                                    additional: props.history.location.state.additional || [],
                                    date: new Date(),
                                  }

                                  props.history.push(`/${props.dados.slug}/pedidos/finalizar`, pedido);
                                }
                              }}
                            >
                              <Close style={{ color: '#F00' }} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                  {/*<TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">{formatoDinheiro(50)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell/>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">{formatoDinheiro(907)}</TableCell>
                  </TableRow>*/}
                </TableBody>
              </Table>

              <table>
                <tr>
                  <th align={'right'}>Subtotal:</th>
                  <td>
                    {formatoDinheiro(
                      carrinho.reduce((total, c) => total + (c.quantidade * c.valor), 0)
                      + carrinho.reduce((total, c) => total + (((c.size && c.sizes.find(el => el.id_product_size === c.size)) || {}).price || 0), 0)
                      + renderCarrinhoAdicional()
                      + renderCarrinhoAdicionalSabor()
                    )}
                  </td>
                </tr>
                <tr>
                  <th align={'right'}>Desconto:</th>
                  <td>
                    {formatoDinheiro(carrinho.reduce((total, c) => total + (c.quantidade * c.desconto), 0) * -1)}
                  </td>
                </tr>
                {totalValueAdicionais ? (
                  <tr>
                    <th align={'right'}>Adicionais:</th>
                    <td>
                      {formatoDinheiro(totalValueAdicionais)}
                    </td>
                  </tr>
                ) : ''}
                <tr>
                  <th align={'right'}>Frete:</th>
                  <td>
                    {formatoDinheiro(tipoEntrega === 'entrega' ? bairro.price : 0)}
                  </td>
                </tr>
                <tr>
                  <th align={'right'}>Total:</th>
                  <td>
                    {formatoDinheiro(
                      totalValueAdicionais +
                      carrinho.reduce((total, c) => total + (c.quantidade * (c.valor - c.desconto)), 0) +
                      carrinho.reduce((total, c) => total + (((c.size && c.sizes.find(el => el.id_product_size === c.size)) || {}).price || 0), 0) +
                      (tipoEntrega === 'entrega' ? bairro.price : 0) +
                      renderCarrinhoAdicional() + renderCarrinhoAdicionalSabor()
                    )}
                  </td>
                </tr>
              </table>
            </CardBody>
          </Card>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default PedidoFinalizar;
