import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const cardBodyStyle = {
    cardBody: {
        padding: "0.9375rem 20px",
        flex: "1 1 auto",
        WebkitBoxFlex: "1",
        position: "relative"
    },
    cardBodyBackground: {
        position: "relative",
        zIndex: "2",
        minHeight: "280px",
        paddingTop: "40px",
        paddingBottom: "40px",
        maxWidth: "440px",
        margin: "0 auto"
    },
    cardBodyPlain: {
        paddingLeft: "5px",
        paddingRight: "5px"
    },
    cardBodyFormHorizontal: {
        paddingLeft: "15px",
        paddingRight: "15px",
        "& form": {
            margin: "0"
        }
    },
    cardPricing: {
        padding: "15px!important",
        margin: "0px!important"
    },
    cardSignup: {
        padding: "0px 30px 0px 30px"
    },
    cardBodyColor: {
        borderRadius: "6px",
        "&": {
            "h1, h2, h3": {
                "& small": {
                    color: "rgba(255, 255, 255, 0.8)"
                }
            }
        }
    },
    cardBodyProfile: {
        marginTop: "15px"
    },
    cardBodyCalendar: {
        padding: "0px !important"
    }
};

function CardBody({...props}) {
    const {
        classes,
        className,
        children,
        background,
        plain,
        formHorizontal,
        pricing,
        signup,
        color,
        profile,
        calendar,
        ...rest
    } = props;
    const cardBodyClasses = classNames({
        [classes.cardBody]: true,
        [classes.cardBodyBackground]: background,
        [classes.cardBodyPlain]: plain,
        [classes.cardBodyFormHorizontal]: formHorizontal,
        [classes.cardPricing]: pricing,
        [classes.cardSignup]: signup,
        [classes.cardBodyColor]: color,
        [classes.cardBodyProfile]: profile,
        [classes.cardBodyCalendar]: calendar,
        [className]: className !== undefined
    });
    return (
        <div className={cardBodyClasses} {...rest}>
            {children}
        </div>
    );
}

CardBody.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    background: PropTypes.bool,
    plain: PropTypes.bool,
    formHorizontal: PropTypes.bool,
    pricing: PropTypes.bool,
    signup: PropTypes.bool,
    color: PropTypes.bool,
    profile: PropTypes.bool,
    calendar: PropTypes.bool
};

export default withStyles(cardBodyStyle)(CardBody);
