import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from "../../assets/img/logo.png";
import LogoNome from "../../assets/img/nome.png";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { HamburguerIcon, CartaoIcon } from "../../assets/icons/Icones";
import Zoom from "@material-ui/core/Zoom";

import { Menu } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  }
}));


export default function PrimarySearchAppBar({ history, dados }) {
  const classes = useStyles();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [location, setLocation] = useState(window.location.pathname);

  useEffect(() => {
    history.listen(location => setLocation(location.pathname));
  }, []);

  return (location === "/") ? (
    <div>
      <Zoom in={true} style={{ transformOrigin: '1 1 1' }} timeout={600}>
        <GridContainer style={{ marginTop: 10, width: 'calc(100% - 15px)', marginLeft: 15 }}>
          <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
            <img alt={"logo"} src={dados.logo} style={{ height: 80 }} />
          </GridItem>
          {dados.logoNome && (
            <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
              <img alt={"logo"} src={dados.logoNome} style={{ height: 70 }} />
            </GridItem>
          )}
        </GridContainer>
      </Zoom>
    </div>
  ) : (
    <div className={classes.grow}>
      <AppBar position="static" style={{ backgroundColor: dados.cores.principal, position: 'fixed', marginTop: -16 }}>
        <Toolbar>
          <GridContainer justify={"space-between"} style={{ marginTop: 10 }}>
            <GridItem lg={1} md={1} sm={1} xs={1} style={{ padding: 0, marginTop: '-3%', display: "flex", justifyContent: 'center', alignItems: 'stretch', flexDirection: 'column', flexWrap: 'wrap' }}>
              {((location.indexOf("/pedidos") > -1) && (location.indexOf("/pedidos/finalizar") === -1)) || location === `/${dados.slug}${location.endsWith('/') ? '/' : ''}` ? (
                <Menu
                  style={{
                    fontSize: "32px",
                    padding: '7',
                    margin: '0',
                    color: dados.cores.destaque,
                    background: '#fff',
                    borderRadius: '50%'
                  }}
                  id="menu-icon"
                />
              ) : (<div/>)}
            </GridItem>
            <Zoom in={true} style={{ transformOrigin: '0 1 0' }} timeout={300}>
              <GridItem lg={2} md={2} sm={2} xs={2} align={"left"}>
                {dados.logoNome && (
                  <img alt={"logo"} src={dados.logo} style={{ height: 55 }} />
                )}
              </GridItem>
            </Zoom>
            <Zoom in={true} style={{ transformOrigin: '0 1 0' }} timeout={300}>
              <GridItem lg={4} md={4} sm={4} xs={4} align={"center"}>
                <img
                  alt={"logo"}
                  src={dados.logoNome || dados.logo}
                  style={{ height: 55 }}
                  onError={e => {
                    e.target.src = 'https://storage.googleapis.com/hust-menu-files/image-placeholder.png';
                  }}
                />
              </GridItem>
            </Zoom>
            <Zoom in={true} style={{ transformOrigin: '0 1 0' }} timeout={300}>
              <GridItem lg={4} md={4} sm={4} xs={4} align={"right"} onClick={() => history.push(`/${dados.slug}/pedidos`)}>
                {(location.indexOf("/pedidos/finalizar") > -1) ? (
                  <>
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      style={{ position: 'absolute', right: 70, top: 30 }}
                    >
                      <path d="M5.5,0,11,10H0Z" transform="translate(0 11) rotate(-90)" fill="#fff"/>
                    </svg>
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        backgroundColor: '#FFF',
                        padding: 10,
                        borderRadius: '50%'
                      }}
                    >
                      <CartaoIcon height={30} width={30} fill={dados.cores.secundaria}/>
                    </div>
                  </>
                ) : ''}
              </GridItem>
            </Zoom>
          </GridContainer>
        </Toolbar>
      </AppBar>
      <p>&nbsp;</p>
    </div>
  );
}
