import MainMenuCliente from "../views/MainMenuCliente";
import PedidoCliente from "../views/PedidoCliente";
import CategoriaProdutoCliente from "../views/CategoriaProdutoCliente";
import CartaoFidelidade from "../views/CartaoFidelidade";
import PedidoFinalizar from "../views/PedidoFinalizar";
import ListarEmpresas from "../views/ListarEmpresas";
import PedidoFinalizadoInfo from "../views/PedidoFinalizadoInfo";

const routes = [
    { path: "/meus-locais", exact: true, name: "Listar lojas", icon: null, component: ListarEmpresas },
    {path: "/:slug/", exact: true, name: 'Principal', icon: null, component: MainMenuCliente},
    {path: "/:slug/pedidos", exact: true, name: 'Pedidos', icon: null, component: PedidoCliente},
    // {path: "/pedidos/:slug", exact: true, name: 'Pedidos', icon: null, component: PedidoCliente},
    {path: "/:slug/pedidos/categoria/:categoria", name: 'Pedidos', icon: null, component: CategoriaProdutoCliente},
    {path: "/:slug/pedidos/finalizar", name: 'Cartão Fidelidade', icon: null, component: PedidoFinalizar},
    {path: "/:slug/pedidos/finalizado", name: 'Cartão Fidelidade', icon: null, component: PedidoFinalizadoInfo},
    {path: "/:slug/cartao-fidelidade", name: 'Cartão Fidelidade', icon: null, component: CartaoFidelidade},
];

export default routes;
