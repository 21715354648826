const CARRINHO_STORAGE = "@clientCart";

export const setCarrinhoStorage = (newPedido, company) => {
  if (!company) throw new Error("Informe a empresa")

  if (newPedido === null) {
    const objetoInicial = { carrinho: {}, additional: [], date: new Date() };

    const carrinho = getCarrinhoStorage() || {};
    carrinho[company] = objetoInicial
    localStorage.setItem(CARRINHO_STORAGE, JSON.stringify(carrinho));
  } else {
    const carrinho = getCarrinhoStorage();
    carrinho[company] = newPedido
    localStorage.setItem(CARRINHO_STORAGE, JSON.stringify(carrinho));
  }
}

export const updateCarrinhoStorage = (newPedido, company) => {
  if (!company) throw new Error("Informe a empresa");

  const carrinho = getCarrinhoStorage();

  carrinho[company] = {
    carrinho: newPedido.carrinho || {},
    adicional: newPedido.additional || [],
    date: newPedido.date || new Date()
  };

  if (carrinho[company].carrinho == null) {
    clearCarrinhoStorage(company);
    setCarrinhoStorage(newPedido, company)
  } else localStorage.setItem(CARRINHO_STORAGE, JSON.stringify(carrinho));
}

// retorna o objeto por completo
export const getCarrinhoStorage = () => {
  try {
    const carrinho = JSON.parse(localStorage.getItem(CARRINHO_STORAGE));
    return carrinho;
  } catch (e) { console.log("Erro ao retornar carrinho", e); }
}
export const getCarrinhoStorageByCompany = (company) => {
  const carrinho = getCarrinhoStorage();
  if (carrinho) return carrinho[company];
  else {
    setCarrinhoStorage(null, company)
    return getCarrinhoStorageByCompany(company)
  }
}

export const clearCarrinhoStorage = (company) => {
  const carrinho = getCarrinhoStorage();
  delete carrinho[company]

  localStorage.setItem(CARRINHO_STORAGE, JSON.stringify(carrinho));
}

