import React, { useState } from "react";
import { Divisor } from "../components/Tourus/Divisor";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import QRCode from "qrcode.react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import Grow from "@material-ui/core/Grow";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";

const Transition = React.forwardRef((props, ref) => <Zoom ref={ref} {...props} />);

const Star = ({ width, height, active, mini }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ marginTop: 12 }}>
    <path
      d={mini ? "M10,10.441,13.1,16.705l6.913,1-5,4.876,1.181,6.885L10,26.22,3.821,29.471,5,22.586,0,17.71l6.913-1Z" : "M16.814,10.441l5.2,10.528,11.619,1.688-8.407,8.195L27.2,42.423,16.814,36.96,6.422,42.423,8.407,30.852,0,22.657l11.618-1.688Z"}
      transform="translate(0 -10.441)"
      fill={active ? "#fac917" : "#414141"}
    />
  </svg>
);

const PickerFidelidade = ({ active, onClick }) => (
  <div
    style={{ width: 100, height: 60, backgroundColor: '#FFF', borderRadius: 10, marginTop: 5, marginBottom: 5 }}
    align={"center"}
    onClick={onClick}
  >
    <Star active={active} width={33} height={31}/>
  </div>
);

const ListaPremios = ({ active, label }) => (
  <div>
    <Star active={active} width={20} height={19} mini/>
    &nbsp;
    <span style={{ marginTop: -2, fontWeight: 'bold', color: '#FFF' }}>
            {label}
        </span>
  </div>
);

export default function CartaoFidelidade({ dados }) {
  const [ modalQrCodeOpen, setModalQrCodeOpen ] = useState(false);

  return (
    <div>
      {/*<Dialog
        open={modalQrCodeOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalQrCodeOpen(false)}
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
          {"Registrar"}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
              <QRCode value="6c2005549a8934144f24af56f42723a2"/>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.finalizarPedido.bind(this)}
                            style={{backgroundColor: '#a75089', color: 'white'}} fullWidth>
                        Fazer pedido
                    </Button>
        </DialogActions>
      </Dialog>*/}
      <div style={{ paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
        <GridContainer justify={"center"}>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Divisor label={"Cartão fidelidade"}/>
            <GridContainer>
              {new Array(9).fill(null).map((prop, key) => (
                <Grow
                  in={true}
                  style={{ transformOrigin: '0 0 0' }}
                  timeout={key * 300}
                >
                  <GridItem lg={4} md={4} sm={4} xs={4} key={key}>
                    <PickerFidelidade
                      // onClick={() => setModalQrCodeOpen(true)}
                      active={key < 4}
                    />
                  </GridItem>
                </Grow>
              ))}
            </GridContainer>

            <Divisor label={"Próximo Prêmio"}/>
            <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={300}>
              <Paper
                elevation={0}
                style={{ zIndex: 1, position: 'relative', background: 'none', marginTop: 0 }}
              >
                <ListaPremios label={"X-Tudo + Refri"}/>
              </Paper>
            </Slide>
            <p/>
            <Divisor label={"Prêmios conquistados"}/>
            {(Object.keys(dados?.produtos).map(prop => dados.produtos[prop]) || []).sort(() => Math.random() - 0.5).slice(0, 3).map((prop, key) => (
              <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={key * 300} key={key}>
                <Paper elevation={0} style={{ zIndex: 1, position: 'relative', background: 'none' }}>
                  <ListaPremios label={prop.nome} active/>
                </Paper>
              </Slide>
            ))}
          </GridItem>
          <p>&nbsp;</p>
        </GridContainer>
      </div>
    </div>
  );
};
