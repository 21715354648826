import React from "react";
import { DrinkerIcone } from "../assets/icons/Icones";
import Button from "@material-ui/core/Button";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import Paper from "@material-ui/core/Paper";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

class CategoriaProdutoCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFinalizarOpen: false,
      quantidade: 1,
      name: '',
      // Icon: DrinkerIcone,
      produtos: []
    };
  }

  async finalizarPedido() {
    await this.setState({ modalFinalizarOpen: false });
    await Swal.fire({ type: "success", title: 'Pedido realizado com sucesso!' });
  }

  componentDidMount() {
    const { name, produtos } = this.props.location.state;
    this.setState({ name, produtos });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.modalFinalizarOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ modalFinalizarOpen: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {/*<DialogTitle id="alert-dialog-slide-title">{"Finalizar Pedido"}</DialogTitle>*/}
          <DialogContent>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Quantidade</p>
                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 30, margin: 0 }}>
                  {this.state.quantidade}
                </p>
                <Slider
                  defaultValue={1}
                  // getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  min={1}
                  max={10}
                  onChange={(e, value) => this.setState({ quantidade: value })}
                />
                <TextField
                  label="Observações"
                  multiline
                  rows="4"
                  margin="normal"
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.finalizarPedido.bind(this)} style={{ backgroundColor: '#a75089', color: 'white' }} fullWidth>
              Fazer pedido
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{ paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
          <GridContainer justify={"center"}>
            <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
              <span style={{ color: "white", textTransform: 'uppercase', fontWeight: 'bold' }}>
                  {this.state.name}
              </span>
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
              <GridContainer>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  {this.state.produtos.map(({ nome, ingredientes, image }, key) => (
                    <Slide
                      direction="down" in={true} mountOnEnter unmountOnExit
                      timeout={key * 80}
                      key={key}
                    >
                      <Paper
                        elevation={0}
                        style={{ zIndex: 1, position: 'relative', background: 'none' }}
                      >
                        <BotaoItem
                          label={nome}
                          ingredientes={ingredientes}
                          // icone={this.state.Icon}
                          image={image}
                          onClick={() => this.setState({ modalFinalizarOpen: true })}
                        />
                      </Paper>
                    </Slide>
                  ))}
                  <p>&nbsp;</p>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

const BotaoItem = ({ label, image, onClick, ingredientes }) => {
  return (
    <Button
      style={{
        color: '#282C34',
        backgroundColor: '#FFFFFF',
        // width: 'calc(100% - 30px)',
        // marginLeft: 15,
        // marginRight: 15,
        height: 110,
        marginTop: 15,
        fontFamily: 'poppins-semiBold'
      }}
      onClick={onClick}
      fullWidth
    >
      <div style={{
        position: 'absolute',
        width: 100,
        height: 100,
        backgroundImage: `url(${image})`,
        left: 5,
        borderRadius: 15,
        border: '2px solid',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 100,
        backgroundPositionY: 'center'
      }}>
        &nbsp;
      </div>
      <div>
        {label}
      </div>
    </Button>
  );

  return (
    <Button
      style={{
        color: '#282C34',
        backgroundColor: '#FFFFFF',
        // width: 'calc(100% - 30px)',
        // marginLeft: 15,
        // marginRight: 15,
        marginTop: 15,
        fontFamily: 'poppins-semiBold'
      }}
      onClick={onClick}
      fullWidth
    >
      <div style={{ float: 'left', position: 'absolute', left: 10, top: 2, lineHeight: 4 }}>
        <img alt={label} src={image} style={{width: 50, height: 50}}/>
      </div>
      <div style={{ float: 'left', position: 'absolute', left: 40, top: 5 }}>
        {label}
      </div>
      <p
        style={{
          fontSize: 8,
          float: 'left',
          width: '100%',
          marginTop: 20,
          textAlign: 'left',
          marginLeft: 31,
          marginBottom: -5
        }}
      >
        {ingredientes}
      </p>
      &nbsp;
    </Button>
  );
};
export default CategoriaProdutoCliente;
