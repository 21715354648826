import React, { useEffect, useState } from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import api from "../api/api";

const ListarEmpresas = () => {
  const [ empresas, setEmpresas ] = useState([]);

  const getCompanies = async () => {
    const empresasLS = JSON.parse(localStorage.getItem('@companies')) || [];
    const { data } = await api.get(`/company/resume/${empresasLS.join(',')}`);
    setEmpresas(data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
      <GridContainer>
        <GridItem lg={12} md={12} sm={12} xs={12} align={"center"}>
          <h3 style={{ color: '#FFF' }}>Escolha o estabelicimento</h3>
        </GridItem>
        {empresas.map(prop => {
          return (
            <GridItem
              lg={3} md={3} sm={4} xs={6}
              style={{ display: 'grid' }}
              onClick={() => {
                window.location.href = `/${prop.slug}`;
              }}
            >
              <Card>
                <CardBody>
                  <img
                    style={{ width: 200, maxWidth: '100%', borderRadius: 10 }}
                    alt={prop.name}
                    src={`https://storage.googleapis.com/hust-menu-files/${prop.uuid}.png`}
                  />
                  <div align={"center"}>
                    <strong>{prop.name}</strong>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
};

export default ListarEmpresas;