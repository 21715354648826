import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Cliente from "./layouts/Cliente";

ReactDOM.render(<Cliente/>, document.getElementById('root'));

/*serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", async event => {
        if (event.target.state === "activated")
          window.location.reload();
      });
      waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
    }
  }
});*/
serviceWorker.unregister();
