import Swal from "sweetalert2";
import axios from "axios";
import { configHost } from "../dados";

const api = axios.create({ baseURL: configHost });

/*api.interceptors.request.use(req => {
  req.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  // console.log(req.headers.Authentication);
  return req;
});*/

api.interceptors.response.use(res => {
  return res;
}, async error => {
  const { data } = error.response;
  let userMessage = `Algo inesperado aconteceu ao processar sua solicitação.`;
  let userDescription = ``;
  const {location} = data;
  if (location) window.location.href = location;

  if (data && (data.message|| data.userMessage)) userMessage = (data.message|| data.userMessage).split('Error: ')[1] || (data.message|| data.userMessage);
  if (data && data.description) userDescription = data.description;

  await Swal.fire({ type: "warning", title: userMessage, text: userDescription });
  throw error;
});

export default api;