import React, { useEffect, useState } from 'react';
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import { CheckCircle } from "@material-ui/icons";
import { QRCode } from "react-qrcode-logo";
import { QrCodePix } from "qrcode-pix";
import Swal from "sweetalert2";

const PedidoFinalizadoInfo = ({ dados, location: { state } }) => {
  const [ qrCodePix, setQrCodePix ] = useState('');

  const { value_total, payment, id_order } = state;
  const { cores: { principal } } = dados;

  useEffect(() => {
    if (payment === 'pix') {
      setQrCodePix(QrCodePix({
        version: '01',
        key: (dados.pixKeyType === 'Telefone' ? '+55' : '') + dados.pixKeyValue, //or any PIX key
        name: dados.nome,
        city: dados.city || 'PALMAS',
        transactionId: `hustIpedidoI${id_order}`,
        message: `${dados.nome} - Pedido #${id_order}`,
        value: value_total,
      }).payload());
    }
  }, [dados.city, dados.nome, dados.pixKeyType, dados.pixKeyValue, id_order, payment, value_total])

  return (
    <div style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15, paddingBottom: 20 }} align={'center'}>
      <Card>
        <CardBody>
          <CheckCircle style={{ fontSize: 50, color: '#66bb6a' }}/>
          <h1>Pedido realizado com sucesso!</h1>
          <h3>Aguarde e em breve seu pedido será entregue!</h3>
          <hr/>
          <QRCode
            value={qrCodePix}
            eyeRadius={[
              { outer: [ 10, 10, 0, 10 ], inner: [ 0, 10, 10, 10 ], },
              [ 10, 10, 10, 0 ],
              [ 10, 0, 10, 10 ]
            ]}
            size={250}
          />
          <span style={{ fontSize: 10 }}>{qrCodePix}</span>
          <br/>
          <br/>
          <button
            style={{
              width: 200,
              height: 35,
              borderRadius: 10,
              background: 'none',
              border: `2px solid ${principal}`,
              color: principal,
              fontWeight: 'bold'
            }}
            onClick={async () => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });

              try {
                await navigator.clipboard.writeText(qrCodePix.trim());
                await Toast.fire('Código copiado com sucesso!', '', 'success');
              } catch (e) {
                await Toast.fire('Não foi possível copiar o código', '', 'error');
              }
            }}
          >
            Copiar Código QR
          </button>
          <hr/>
          <span>
            Abra o aplicativo do seu banco e nas opções de PIX cole o código copiado ou leia o QRCode.
          </span>
        </CardBody>
      </Card>
    </div>
  );
}

export default PedidoFinalizadoInfo;