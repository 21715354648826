import React from "react";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import { HamburguerIcon, CartaoIcon } from "../assets/icons/Icones";
import { IconeMenu } from "../components/Tourus/IconeMenu";
import { Redirect } from 'react-router-dom';

class MainMenuCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // - -
    };
  }

  render() {
    return <Redirect to={`/${window.location.pathname.split('/')[1]}/pedidos`}/>

    return (
      <div style={{ paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
        <GridContainer justify={"center"}>
          <GridItem lg={8} md={8} sm={8} xs={8}>
            <IconeMenu
              label={"pedidos"}
              icone={HamburguerIcon}
              local={`/${window.location.pathname.split('/')[1]}/pedidos`}
              history={this.props.history}
            />
          </GridItem>
          <GridItem lg={8} md={8} sm={8} xs={8}>
            <IconeMenu
              label={"Catão Fidelidade"}
              icone={CartaoIcon}
              local={`/${window.location.pathname.split('/')[1]}/cartao-fidelidade`}
              history={this.props.history}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default MainMenuCliente;
