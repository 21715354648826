import React from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export const MenuCategoria = props => (
  <ListItem style={{ padding: 0 }} button divider>
    <ListItem button>
      <ListItemText primary={props.label} />
    </ListItem>
  </ListItem>
);
